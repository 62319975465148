import { useCallback, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ContactButton = styled.button<{
  $bg?: string;
  $reverse?: boolean;
  $disabled?: boolean;
  $color?: string;
}>`
  border-radius: 16px;
  border: 2px solid ${(props) => props.$bg || '#fff'};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.8 : 1)};
  padding: 10px 24px;
  font-weight: 500;
  font-size: 14px;
  background-color: ${(props) =>
    props.$reverse ? 'transparent' : props.$bg || '#fff'};
  color: ${(props) => (props.$color ? '#fff' : '#252a33')};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${(props) =>
      props.$disabled || props.$reverse ? props.$bg : 'transparent'};
    color: ${(props) =>
      props.$color === '#fff'
        ? '#1d1d1d'
        : props.$reverse || props.$disabled
        ? '#fff'
        : props.$color};
  }
`;

const Navbar = () => {
  const [headerStyle, setHeaderStyle] = useState('header-static');

  const listenScrollEvent = () => {
    if (window.scrollY > 48) {
      setHeaderStyle('header-shadow');
    } else {
      setHeaderStyle('header-static');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  return (
    <nav
      style={{
        position: 'fixed',
        borderBottom: '1px solid transparent',
        transition: 'border-bottom, background-color 0.4s ease',
        minHeight: '72px',
        width: '100%',
        lineHeight: '60px',
        fontSize: '1rem',
        zIndex: 999999,
      }}
      className={headerStyle}
    >
      <div
        className={`menu-icon ${isMenuOpen ? 'open-bar' : 'nothing'}`}
        onClick={toggleMenu}
      >
        <i></i>
        <i></i>
      </div>

      <div className='logo'>
        <Nav.Link
          to='/'
          as={NavLink}
          className='d-flex align-items-center'
          onClick={closeMenu}
        >
          <img
            alt='pletava'
            src='/img/pletavaLogoBlack.svg'
            width='18px'
            className='d-inline-block'
            style={{ marginRight: '4px', marginTop: '10px' }}
          />
          {/* <span
            style={{ fontWeight: 600, color: '#252a33', fontFamily: 'Nunito' }}
          >
            PLET
          </span>
          <span
            style={{ fontWeight: 500, color: '#252a33', fontFamily: 'Nunito' }}
          >
            AVA
          </span> */}
        </Nav.Link>
      </div>

      <div className='menu'>
        <ul className={isMenuOpen ? 'showing' : ''}>
          <li className='first-menu-item'>
            <Nav.Link
              to='/'
              as={NavLink}
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Home
            </Nav.Link>
          </li>
          <li>
            <Nav.Link
              to='/testimonials'
              as={NavLink}
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Testimonials
            </Nav.Link>
          </li>
          <li>
            <Nav.Link
              to='/case-studies'
              as={NavLink}
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Case Studies
            </Nav.Link>
          </li>
          <li>
            <Nav.Link
              to='/career'
              as={NavLink}
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Career
            </Nav.Link>
          </li>
          <li>
            <Nav.Link
              to='/contact-us'
              as={NavLink}
              className='nav-item-margin'
              onClick={closeMenu}
            >
              <ContactButton
                className='contact-nav-btn'
                $bg='#2b93eb'
                $reverse={true}
                style={{
                  height: '32px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontSize: '14px',
                }}
              >
                Contact
              </ContactButton>
            </Nav.Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
