import React from 'react'
import { Container, StyledHeader } from './HomeHeader'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ApplyForm from './ApplyForm'
import Footer from './Footer'
import { StyledVaccancyWraper } from './CareerFrontend'

const CareerUiDesign = () => {
  return (
    <>
      <StyledHeader style={{ paddingBottom: '152px' }}>
        <Container>
          <h2 style={{ marginBottom: '16px' }}>Senior UI/UX Designer</h2>

          <p style={{ marginBottom: '32px' }}>
            Unlock the Future: UI/UX designer Wanted for Exciting FinTech
            Project
          </p>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <LocationOnIcon
              sx={{
                fontSize: 24,
                marginRight: '8px',
                color: '#252a33',
              }}
            />
            <span style={{ fontSize: '16px' }}>Remote, Any Country</span>
          </div>
        </Container>
      </StyledHeader>
      <StyledVaccancyWraper>
        <div className='vac-mb'>
          <h2>Responsibilities:</h2>
          <p>
            In this pivotal role, you will be an integral part of our
            cross-functional Design team, collaborating closely with fellow
            developers, QA/Test Engineers, product owners, and scrum masters.
            Your key responsibilities include:
          </p>
          <ul>
            <li>
              Spearhead the design and implementation of pixel-perfect,
              mobile-first user interfaces using tools like Figma and other
              industry-standard design software.
            </li>
            <li>
              Collaborate seamlessly with cross-functional teams to
              comprehensively understand project requirements and contribute to
              an overarching user experience strategy.
            </li>
            <li>
              Conduct meticulous user research and usability testing to derive
              actionable insights for informed and data-driven design decisions.
            </li>
            <li>
              Mentor and guide junior designers, fostering a collaborative and
              innovative design culture within the team.
            </li>
            <li>
              Stay at the forefront of industry trends, design tools, and
              emerging technologies to continuously elevate and refine design
              processes.
            </li>
          </ul>
        </div>
        <div className='vac-mb'>
          <h2>Required Qualifications and Skills:</h2>
          <p>
            To excel in this role, you should possess the following
            qualifications:
          </p>
          <ul>
            <li>
              Proven expertise as a UI/UX designer, showcasing a robust
              portfolio of successful projects.
            </li>
            <li>
              Proficiency in design tools such as Figma, Adobe XD, or Sketch,
              coupled with a deep understanding of design principles.
            </li>
            <li>
              Strong communication skills, enabling the clear articulation of
              design concepts and rationale.
            </li>
            <li>
              Effective collaboration with developers, product managers, and
              other stakeholders to ensure seamless integration of design
              elements.
            </li>
            <li>
              Hands-on experience in conducting user research and usability
              testing.
            </li>
          </ul>
        </div>
        <div className='vac-mb'>
          <h2>Nice to have:</h2>
          <ul style={{ paddingTop: 0 }}>
            <li>
              Familiarity with motion design and proficiency in prototyping
              tools.
            </li>
            <li>
              Previous experience working in an agile development environment.
            </li>
          </ul>
        </div>
        <div className='vac-mb'>
          <h2>We offer:</h2>
          <ul style={{ paddingTop: 0 }}>
            <li>Flexible working hours</li>
            <li>Working remotely</li>
            <li>Multisport sports card</li>
            <li>100.00 Euro in food vouchers</li>
            <li>Additional health insurance package</li>
            <li>Life insurance with accident coverage</li>
            <li>
              Access to training through various platforms, in-house training,
              access to professional events and conferences
            </li>
            <li>
              Company package with discounts and promotions for services,
              purchase of goods, equipment, etc.
            </li>
            <li>A work environment with a minimum level of hierarchy</li>
            <li>Family-like culture with a comfortable working atmosphere</li>
            <li>Competitive сompensation</li>
            <li>Personal and professional development opportunities</li>
            <li>Team building activities</li>
          </ul>
        </div>
        <p>Note:</p>
        <p>
          Please be advised that due to the anticipated volume of applications,
          we regret that we will only be able to contact shortlisted candidates.
        </p>
        <ApplyForm />
      </StyledVaccancyWraper>
      <Footer />
    </>
  )
}

export default CareerUiDesign
