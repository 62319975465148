import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

import TextField from '@mui/material/TextField';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ContactButton } from './Navbar';
import { contactsCollectionRef } from '../hooks/useFirebase';
import { addDoc, serverTimestamp } from 'firebase/firestore';
import { emailRegexp } from '../constants/constants';

const ContactContainerWrapper = styled.div<{
  $extraMargin: boolean | undefined;
}>`
  /* background-color: #f4fbff; */
  padding: 116px 128px;
  padding-top: ${(props) => (props.$extraMargin ? '80px' : '32px')};

  h4,
  h2 {
    color: #252a33;
    font-weight: 600;
  }

  .contact-submit {
    width: 100%;
  }

  .first-col {
    padding: 48px;
    padding-right: 8px;
  }

  .second-col {
    padding: 48px;
    padding-left: 8px;
  }

  @media (max-width: 768px) {
    padding: 116px 1rem;
    padding-top: 32px;
  }

  @media (max-width: 992px) {
    .c-line {
      display: none !important;
    }
    .first-col {
      padding: 32px;
    }
    .second-col {
      padding: 32px;
    }
  }
`;

const ContactContainer = ({ extraMargin }: { extraMargin?: boolean }) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const formRef = useRef<HTMLFormElement>(null);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length < 40) {
      setName(newValue);
    }
  }, []);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isValid = emailRegexp.test(newValue);
    setIsEmailValid(isValid);
    if (newValue.length < 40) {
      setEmail(newValue);
    }
  }, []);

  const handleCompanyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (newValue.length < 40) {
        setCompany(newValue);
      }
    },
    []
  );

  const handleEmail = useCallback(() => {
    const EMAIL_SERVICE_ID = process.env.REACT_APP_emailServiceId;
    const EMAIL_TEMPLATE_ID = process.env.REACT_APP_emailTemplateId;
    const EMAIL_PUBLIC_KEY = process.env.REACT_APP_emailPublicKey;

    if (
      !EMAIL_SERVICE_ID ||
      !EMAIL_TEMPLATE_ID ||
      !EMAIL_PUBLIC_KEY ||
      !formRef.current
    ) {
      console.log('something went wrong');
      return;
    }

    emailjs.sendForm(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, formRef.current, {
      publicKey: EMAIL_PUBLIC_KEY,
    });
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = emailRegexp.test(email);

    setIsEmailValid(isValid);

    if (isValid && name !== '' && email !== '' && company !== '') {
      await addDoc(contactsCollectionRef, {
        Name: name,
        Email: email,
        Company: company,
        CreatedAt: serverTimestamp(),
      });
      handleEmail();
      setName('');
      setEmail('');
      setCompany('');
      alert(
        'Thank you for taking the first step for our partnership! Our consultant will be in touch with you in 24 hours.'
      );
    } else {
      alert(
        'Some of the inputs are invalid. Please check your answers and submit again.'
      );
    }
  };

  return (
    <ContactContainerWrapper $extraMargin={extraMargin}>
      <Container>
        <Row
          style={{
            backgroundColor: '#fbfdff',
            border: '1px solid #e3eaef',
            borderRadius: '8px',
          }}
        >
          <Col md={12} lg={5} className='first-col'>
            <h2>Let’s talk!</h2>
            <p style={{ marginBottom: '32px' }}>
              Please fill in the form below and a Pletava expert will contact
              you in 24 hours directly to see how we can help your business
              grow.
            </p>
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className='c-form-container'
            >
              <TextField
                id='name'
                name='name'
                value={name}
                label='Name'
                variant='outlined'
                required
                style={{ width: '100%', marginBottom: '16px' }}
                onChange={handleNameChange}
              />
              <TextField
                id='email'
                name='email'
                value={email}
                label='Email'
                variant='outlined'
                required
                style={{ width: '100%', marginBottom: '16px' }}
                onChange={handleEmailChange}
                error={!isEmailValid}
                helperText={!isEmailValid ? 'Invalid email' : undefined}
              />
              <TextField
                id='company'
                name='company'
                value={company}
                label='Your Company'
                variant='outlined'
                required
                style={{ width: '100%', marginBottom: '16px' }}
                onChange={handleCompanyChange}
              />
              <p style={{ marginBottom: '24px', fontWeight: 300 }}>
                By submitting this form you are agreeing with our{' '}
                <span style={{ color: '#747474', textDecoration: 'underline' }}>
                  <Nav.Link
                    to='/privacy-policy'
                    as={NavLink}
                    style={{ display: 'inline' }}
                  >
                    privacy policy
                  </Nav.Link>
                </span>
                .
              </p>
              <ContactButton
                type='submit'
                className='contact-submit'
                $bg='#2b93eb'
                $color='#252a33'
                $disabled={
                  name === '' || email === '' || company === '' || !isEmailValid
                }
                disabled={
                  name === '' || email === '' || company === '' || !isEmailValid
                }
              >
                Contact Us
              </ContactButton>
            </form>
          </Col>
          <Col
            md={0}
            lg={1}
            className='d-flex align-items-center justify-content-center c-line'
          >
            <div
              style={{
                height: '400px',
                width: '1px',
                backgroundColor: '#747474',
              }}
            ></div>
          </Col>
          <Col md={12} lg={6} className='second-col'>
            <h4>Trusted by 50+ impactful companies in 16 countries</h4>
            <Container style={{ margin: '24px 0', padding: 0 }}>
              <Row>
                <Col>
                  <img src='./img/client1.svg' alt='' />
                </Col>
                <Col>
                  <img src='./img/client2.svg' alt='' />
                </Col>
                <Col>
                  <img src='./img/client3.svg' alt='' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src='./img/client4.svg' alt='' />
                </Col>
                <Col>
                  <img src='./img/client5.svg' alt='' />
                </Col>
                <Col>
                  <img src='./img/client6.svg' alt='' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src='./img/client7.svg' alt='' />
                </Col>
                <Col>
                  <img src='./img/client8.svg' alt='' />
                </Col>
                <Col>
                  <img src='./img/client9.svg' alt='' />
                </Col>
              </Row>
            </Container>
            <h4>
              Discover the value of working with Pletava — in our customers'
              words.
            </h4>
            <p style={{ color: '#747474' }}>
              While we cannot showcase the complete list of our projects due to
              NDA, we have received numerous positive customer reviews that can
              provide you with a clear understanding of what you can expect from
              our collaboration.
            </p>
          </Col>
        </Row>
      </Container>
    </ContactContainerWrapper>
  );
};

export default ContactContainer;
