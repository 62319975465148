import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Error = () => {
  return (
    <div style={{ paddingTop: '72px' }}>
      <h1 style={{ color: '#252a33' }}>This page does not exist : (</h1>
      <Nav.Link
        to='/'
        as={NavLink}
        style={{ color: '#ec4858', textDecoration: 'underline' }}
      >
        Return to the home screen
      </Nav.Link>
    </div>
  )
}

export default Error
