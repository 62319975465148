import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'

const ProblemsWrapper = styled.div`
  /* position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url('/img/PletavaWork.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed; */
  padding: 32px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`

const CardWrapper = styled.div`
  background: #f7f7f7;
  padding: 32px;
  margin: 16px;
  border-radius: 16px;
  /* box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5); */
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  /* backdrop-filter: blur(8px); */

  @media (max-width: 768px) {
    margin: 16px 0;
  }
`

const Card = ({
  title,
  description,
}: {
  title: string
  description: string
}) => (
  <Col xs={12} sm={6} md={6}>
    <CardWrapper>
      <h3 style={{ marginBottom: '16px', fontWeight: 600, color: '#252a33' }}>
        {title}
      </h3>
      <p style={{ fontWeight: 600, lineHeight: 1.4, color: '#75808a' }}>
        {description}
      </p>
    </CardWrapper>
  </Col>
)

const Problems = () => {
  return (
    <ProblemsWrapper id='problems'>
      <Container style={{ position: 'relative', zIndex: 9 }}>
        <Row>
          <Col md={12} lg={4}>
            <h1 style={{ color: '#252a33', marginTop: '16px' }}>
              Aren’t these software outsourcing challenges familiar?
            </h1>
          </Col>
          <Col md={12} lg={8}>
            <Row>
              <Col md={12}>
                <Row>
                  <Card
                    title='Local talent shortage'
                    description='You have to compete with tech giants to hire developers near
                  you'
                  />
                  <Card
                    title='Lack of flexibility'
                    description='Scaling your team up & down causes unbearable admin hassle'
                  />
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Card
                    title='Slow hiring'
                    description='Acquiring senior developers and QA engineers takes forever'
                  />
                  <Card
                    title='Clash of cultures'
                    description='You struggle to integrate remote offshore developers into your
                  team'
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ProblemsWrapper>
  )
}

export default Problems
