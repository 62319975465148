import React from 'react'
import HomeHeader from '../components/HomeHeader'
import Reviews from '../components/Reviews'
import Problems from '../components/Problems'
import Magazines from '../components/Magazines'
import Authority from '../components/Authority'
import Solutions from '../components/Solutions'
import WhyPletava from '../components/WhyPletava'
import Services from '../components/Services'
import Expertise from '../components/Expertise'
import ReviewsSliderContainer from '../components/ReviewsSliderContainer'
import ContactContainer from '../components/ContactContainer'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div style={{ paddingTop: '72px' }}>
      <HomeHeader />
      <Reviews />
      <Problems />
      <Magazines />
      <Authority />
      <Solutions />
      <WhyPletava />
      <Services />
      <Expertise />
      <ReviewsSliderContainer />
      <ContactContainer />
      <Footer />
    </div>
  )
}

export default Home
