import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const FooterWrapper = styled.footer`
  padding: 32px 64px 24px 64px;

  border-top: 1px solid #e3eaef;
  border-radius: 8px;

  h4 {
    font-weight: 400;
    font-size: 1.2em;
    margin-bottom: 16px;
  }

  p,
  a {
    font-size: 14px;
    margin: 0;
    margin-bottom: 8px;
  }

  .bottom-cols:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
    }
  }

  a {
    color: #252a33;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    padding: 32px 1rem 24px 1rem;

    .bottom-cols {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 992px) {
    .f-col {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper style={{ display: 'flex', justifyContent: 'center' }}>
      <Container>
        <Row>
          <Col md={12} lg={3} className='f-col'>
            <Nav.Link
              to='/'
              as={NavLink}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                alt='pletava'
                src='/img/pletavaLogoBlack.svg'
                width='24px'
                className='d-inline-block'
                style={{ marginRight: '12px' }}
              />
              <div style={{ fontSize: '24px' }}>
                <span
                  style={{
                    fontWeight: 600,
                    color: '#252a33',
                    fontFamily: 'Nunito',
                  }}
                >
                  PLET
                </span>
                <span
                  style={{
                    fontWeight: 500,
                    color: '#252a33',
                    fontFamily: 'Nunito',
                  }}
                >
                  AVA
                </span>
              </div>
            </Nav.Link>
          </Col>
          <Col md={6} lg={2} className='f-col'>
            <h4>Contacts</h4>
            <a
              style={{ display: 'block', marginBottom: '8px' }}
              href='mailto:info@pletava.com'
            >
              info@pletava.com
            </a>
            <a
              style={{ display: 'block', marginBottom: '8px' }}
              href='tel:+353-85-201-6811'
            >
              +353 (85) 201 6811
            </a>
            <p>Ireland & Ukraine</p>
          </Col>
          <Col md={0} lg={1}></Col>
          {/* <Col md={6} lg={2} className='f-col'>
            <h4>Expertise</h4>
            <p>Mobile App Developement</p>
            <p>Custom Web Developement</p>
            <p>UI/UX Design Services</p>
            <p>Cloud & DevOps Services</p>
            <p>Quality Assurance</p>
          </Col> */}
          <Col md={6} lg={2} className='f-col'>
            <h4>Services</h4>
            <p>Flex Teams</p>
            <p>Dedicated Teams</p>
          </Col>
          <Col md={6} lg={2} className='f-col'>
            <h4>Company</h4>
            <Nav.Link to='/testimonials' as={NavLink}>
              <p>Testimonials</p>
            </Nav.Link>
            <Nav.Link to='/case-studies' as={NavLink}>
              <p>Our Work</p>
            </Nav.Link>
            <Nav.Link to='/career' as={NavLink}>
              <p>Career</p>
            </Nav.Link>
          </Col>
          <Col md={6} lg={2} className='f-col'>
            <Nav.Link
              to='https://topsoftwarecompanies.co/software-development/agencies/ireland'
              as={NavLink}
            >
              <img
                src='https://topsoftwarecompanies.co/badges/top-software-developers.png'
                alt='software developers ireland'
                title='Software Developers Ireland'
                style={{ width: '100px' }}
              />
            </Nav.Link>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col md={12} lg={4} className='bottom-cols'>
            <Nav.Link
              to='/privacy-policy'
              as={NavLink}
              style={{ display: 'inline', marginRight: '32px' }}
            >
              <span style={{ color: '#747474' }}>Privacy Policy</span>
            </Nav.Link>
            <Nav.Link
              to='/terms-of-use'
              as={NavLink}
              style={{ display: 'inline' }}
            >
              <span style={{ color: '#747474' }}>Terms of Use</span>
            </Nav.Link>
          </Col>
          <Col
            md={12}
            lg={4}
            style={{ display: 'flex', justifyContent: 'center' }}
            className='bottom-cols'
          >
            <a href='https://instagram.com'>
              <InstagramIcon
                sx={{ fontSize: 24, marginRight: '16px', color: '#323844' }}
              />
            </a>
            <a href='https://facebook.com'>
              <FacebookIcon
                sx={{ fontSize: 24, marginRight: '16px', color: '#323844' }}
              />
            </a>
            <a href='https://linkedin.com'>
              <LinkedInIcon
                sx={{ fontSize: 24, marginRight: '16px', color: '#323844' }}
              />
            </a>
            <a href='https://twitter.com'>
              <TwitterIcon sx={{ fontSize: 24, color: '#323844' }} />
            </a>
          </Col>
          <Col md={12} lg={4} className='bottom-cols'>
            <p style={{ color: '#747474' }}>
              © 2020 - 2024 All rights reserved. Pletava Ltd.
            </p>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
