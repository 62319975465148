import React from 'react';
import styled from 'styled-components';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import ForestRoundedIcon from '@mui/icons-material/ForestRounded';
import { Col, Container, Row } from 'react-bootstrap';

const ReviewWrapper = styled.div`
  background-color: #ffffff;
  margin: -92px 24px 64px 24px;
  padding: 16px 40px 40px;
  border-radius: 8px;
  box-shadow: 0 0 9px 8px rgba(103, 121, 138, 0.16);

  .icon-wrap {
    padding: 16px;
    border: 1px solid #e3eaef;
    border-radius: 16px;
    margin-bottom: 16px;
  }

  .col-rev {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .second-row {
    @media (max-width: 768px) {
      padding: 0 20px;

      .mb-item {
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 16px 8px 8px;

    .col-rev {
      margin-bottom: 40px;
    }
  }
`;

const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    line-height: 1.5;
  }

  h3 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  @media (max-width: 768px) {
    /* flex-direction: column;
    align-items: flex-start; */
    padding: 0 16px;
    font-size: 0.8rem;
  }
`;

const ReviewRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Reviews = () => {
  return (
    <>
      <ReviewWrapper>
        <ReviewRow style={{ marginBottom: '40px', marginTop: '16px' }}>
          <Container>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col xs={12} lg={2} className='col-rev'>
                <div className='img-review'>
                  <img src='/img/PletavaUBA.svg' alt='Pletava UBA' />
                </div>
              </Col>
              <Col xs={6} lg={2} className='col-rev'>
                <div className='img-review'>
                  <img
                    src='/img/PletavaClutchDev2021.svg'
                    alt='Pletava Clutch'
                  />
                </div>
              </Col>
              <Col xs={6} lg={2} className='col-rev'>
                <div className='img-review'>
                  <img
                    src='/img/PletavaClutchReact2022.svg'
                    alt='Pletava Clutch React'
                  />
                </div>
              </Col>
              <Col xs={6} lg={2} className='col-rev'>
                <div className='img-review'>
                  <img src='/img/PletavaTop20.svg' alt='Pletava top 20' />
                </div>
              </Col>
              <Col xs={6} lg={2} className='col-rev'>
                <div className='img-review'>
                  <img
                    src='/img/PletavaDesignRush.svg'
                    alt='Pletava Design Rush'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </ReviewRow>
        <hr style={{ marginBottom: '40px' }} />
        <ReviewRow className='second-row'>
          <Container>
            <Row
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Col xs={12} lg={2}>
                <ReviewItem className='mb-item'>
                  <div
                    className='icon-wrap'
                    style={{
                      backgroundColor: '#eafff7',
                    }}
                  >
                    <ForestRoundedIcon
                      sx={{ fontSize: 48, color: '#5cbd95' }}
                      color='primary'
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h3 style={{ margin: 0 }}>CO2-Neutral</h3>
                    <p style={{ margin: 0 }}>
                      Offsetting unavoidable emissions by reforestation
                    </p>
                  </div>
                </ReviewItem>
              </Col>
              <Col xs={12} lg={2}>
                <ReviewItem className='mb-item'>
                  <div
                    className='icon-wrap'
                    // style={{ backgroundColor: '#f4fbff' }}
                  >
                    <LockPersonRoundedIcon
                      sx={{ fontSize: 48, color: '#43aaff' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h3 style={{ margin: 0 }}>ISO 27001</h3>
                    <p style={{ margin: 0 }}>
                      Data security certification by Bureau Veritas
                    </p>
                  </div>
                </ReviewItem>
              </Col>
              <Col xs={12} lg={2}>
                <ReviewItem
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <h4 style={{ margin: 0 }}>KEEP</h4>
                  <h4 style={{ margin: 0 }}>MOVING</h4>
                  <h4>FORWARD</h4>
                </ReviewItem>
              </Col>
            </Row>
          </Container>
        </ReviewRow>
      </ReviewWrapper>
      <></>
    </>
  );
};

export default Reviews;
