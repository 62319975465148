import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const ExpertiseWrapper = styled.div`
  padding: 116px 128px;
  padding-top: 0;

  .exp-col {
    padding-left: 0;
    padding-right: 40px;

    &:last-child {
      padding-right: 0;
    }
  }

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  .consultation {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &::after {
      content: '⸺>';
      display: inline-block;
      margin-left: 16px;
      font-weight: 600;
      transition: all 0.2s ease;
    }

    &:hover {
      text-decoration: none;
      transition: all 0.2s ease;

      &::after {
        margin-left: 40px;
        transition: all 0.2s ease;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 116px 1rem;
    padding-top: 32px;
  }
`

const Expertise = () => {
  return (
    <ExpertiseWrapper>
      <h2>Our expertise</h2>
      <Container style={{ paddingLeft: 0 }}>
        <Row style={{ margin: '24px 0' }}>
          <Col md={12} lg={4} className='exp-col'>
            <p>
              Wide range of industries, including EdTech, Healthcare, GreenTech,
              and Ecommerce
            </p>
          </Col>
          <Col md={12} lg={4} className='exp-col'>
            <p>
              Custom services for development projects at any stage, including
              Discovery, Project Management, and DevOps
            </p>
          </Col>
          <Col md={12} lg={4} className='exp-col'>
            <p>
              All popular programming languages and major platforms, including
              Python, React, Flutter, and Angular
            </p>
          </Col>
        </Row>
      </Container>
      <Nav.Link
        to='/contact-us'
        as={NavLink}
        style={{ display: 'inline-block' }}
      >
        <p className='consultation'>Request a free discovery consultation</p>
      </Nav.Link>
    </ExpertiseWrapper>
  )
}

export default Expertise
