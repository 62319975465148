import React from 'react';
import { Container, StyledHeader } from './HomeHeader';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styled from 'styled-components';
import ApplyForm from './ApplyForm';
import Footer from './Footer';

export const StyledVaccancyWraper = styled.div`
  padding: 32px 192px;
  /* background-color: #f4fbff; */

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  p {
    font-size: 16px;
    color: #323844;
  }

  ul {
    padding: 30px 80px;
  }

  li {
    font-size: 16px;
    list-style: outside;
    list-style-position: outside;
    margin-bottom: 8px;
  }

  .vac-mb {
    margin-bottom: 64px;
  }

  @media (max-width: 768px) {
    padding: 24px 1rem;

    ul {
      padding: 24px 32px;
    }
  }
`;

const CareerFrontend = () => {
  return (
    <>
      <StyledHeader style={{ paddingBottom: '152px' }}>
        <Container>
          <h2 style={{ marginBottom: '16px' }}>
            Frontend Software Engineer (React Native)
          </h2>

          <p style={{ marginBottom: '32px' }}>
            Unlock the Future: Frontend Engineer Wanted for Exciting AR
            Streaming Project
          </p>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <LocationOnIcon
              sx={{
                fontSize: 24,
                marginRight: '8px',
                color: '#252a33',
              }}
            />
            <span style={{ fontSize: '16px' }}>Remote, Any Country</span>
          </div>
        </Container>
      </StyledHeader>
      <StyledVaccancyWraper>
        <div className='vac-mb'>
          <h2>Responsibilities:</h2>
          <p>
            In this pivotal role, you will be an integral part of our
            cross-functional DevSecOps team, collaborating closely with fellow
            developers, QA/Test Engineers, UX/UI designers, product owners, and
            scrum masters. Your key responsibilities include:
          </p>
          <ul>
            <li>
              Design and Develop: Craft and maintain mobile applications for
              both iOS and Android platforms, ensuring their seamless
              functionality and user experience.
            </li>
            <li>
              Code Excellence: Create secure, clean, maintainable, and highly
              efficient code, following industry-leading coding standards and
              best practices.
            </li>
            <li>
              Feature Development: Collaborate actively with various teams to
              conceive, design, develop, and launch innovative features that
              enhance our product’s capabilities.
            </li>
            <li>
              Quality Assurance: Implement and rigorously adhere to coding
              standards and best practices, while also undertaking debugging and
              troubleshooting to rectify issues.
            </li>
            <li>
              Technology Evolution: Stay abreast of emerging trends and
              technologies within the frontend development landscape,
              integrating these insights into your work.
            </li>
            <li>
              Mentorship: Assume a leadership role by mentoring and guiding
              junior frontend engineers, fostering their professional growth.
            </li>
            <li>
              Quality Assurance: Participate in code reviews to ensure that our
              applications consistently meet high-quality standards.
            </li>
            <li>
              Optimisation: Lead efforts to fully optimise mobile apps for
              top-tier performance and robust security.
            </li>
          </ul>
        </div>
        <div className='vac-mb'>
          <h2>Required Qualifications and Skills:</h2>
          <p>
            To excel in this role, you should possess the following
            qualifications:
          </p>
          <ul>
            <li>
              React Native Expertise: Demonstrated proficiency in React Native
              and native programming for iOS and Android platforms, with a
              minimum of 3 years of experience.
            </li>
            <li>
              AR and 3D Rendering: Experience with 3D rendering and familiarity
              with renderers such as SceneKit or OpenGL, along with expertise in
              integrating AR algorithms.
            </li>
            <li>
              Architectural Acumen: A strong understanding of mobile app
              architecture, design patterns, and software development
              principles.
            </li>
            <li>
              Communication Skills: Exceptional written and verbal communication
              skills in English, at least at the Upper-Intermediate level.
            </li>
            <li>
              Team Collaboration: A collaborative team spirit, characterized by
              the willingness to share, challenge, and exchange ideas.
            </li>
            <li>
              Open-Mindedness: An open mindset that values diversity across all
              fields and embraces constructive disagreement while ensuring
              commitment to collective goals.
            </li>
            <li>
              Agile Expertise: Proficiency in agile software development
              methodologies, particularly Scrum, and an understanding of the
              corresponding ceremonies.
            </li>
            <li>
              Problem-Solving Aptitude: Excellent problem-solving, debugging,
              and troubleshooting skills that contribute to the overall success
              of our projects.
            </li>
          </ul>
        </div>
        <div className='vac-mb'>
          <h2>Nice to have:</h2>
          <ul style={{ paddingTop: 0 }}>
            <li>
              Understanding backend technologies – such as Node.js, GraphQL, and
              MongoDB – as well as experience with AR programming is a plus
            </li>
            <li>
              Written and verbal communication skills in German are beneficial
            </li>
          </ul>
        </div>
        <div className='vac-mb'>
          <h2>We offer:</h2>
          <ul style={{ paddingTop: 0 }}>
            <li>Flexible working hours</li>
            <li>Working remotely</li>
            <li>Multisport sports card</li>
            <li>100.00 Euro in food vouchers</li>
            <li>Additional health insurance package</li>
            <li>Life insurance with accident coverage</li>
            <li>
              Access to training through various platforms, in-house training,
              access to professional events and conferences
            </li>
            <li>
              Company package with discounts and promotions for services,
              purchase of goods, equipment, etc.
            </li>
            <li>A work environment with a minimum level of hierarchy</li>
            <li>Family-like culture with a comfortable working atmosphere</li>
            <li>Competitive сompensation</li>
            <li>Personal and professional development opportunities</li>
            <li>Team building activities</li>
          </ul>
        </div>
        <p>Note:</p>
        <p>
          Please be advised that due to the anticipated volume of applications,
          we regret that we will only be able to contact shortlisted candidates.
        </p>
        <ApplyForm />
      </StyledVaccancyWraper>
      <Footer />
    </>
  );
};

export default CareerFrontend;
