import React from 'react'
import { Carousel } from 'react-bootstrap'
import styled from 'styled-components'

const ReviewCarouselWrapper = styled.div`
  background-color: #fbfdff;
  border: 1px solid #e3eaef;
  border-radius: 8px;

  .c-item {
    width: 100%;
    height: 400px;
    padding: 40px 12%;

    @media (max-width: 992px) {
      height: 520px;
    }

    @media (max-width: 576px) {
      height: 568px;
      padding: 40px 16%;
      padding-top: 16px;
    }
  }

  .c-content {
    display: flex;
    align-items: center;
    height: 100%;

    p::before {
      content: '⸺';
      display: inline-block;
      margin-right: 8px;
      font-weight: 600;
    }

    @media (max-width: 1200px) {
      h4 {
        font-size: 1em;
      }
    }
    @media (max-width: 992px) {
      flex-direction: column;
      h4 {
        font-size: 16px;
      }
      p,
      a {
        font-size: 12px;
      }
    }
  }

  .img-wrapper {
    width: 136px;
    min-width: 136px;
    border-radius: 8px;
    margin-right: 48px;

    @media (max-width: 992px) {
      margin-bottom: 32px;
    }

    @media (max-width: 576px) {
      margin-right: 0;
    }
  }
`

const ReviewCarousel = () => {
  return (
    <ReviewCarouselWrapper>
      <Carousel data-bs-theme='dark' interval={5000}>
        <Carousel.Item className='c-item'>
          <div className='c-content'>
            <div className='img-wrapper'>
              <img src='./img/user1.png' alt='Pletava Customer' />
            </div>
            <div>
              <h4>
                “The main, positive aspect of working with Pletava is their
                Growth mentality, which can be felt throughout the company. I
                know what to expect from the people there, and it’s encouraging.
                From a business perspective, this partnership was a great
                success for us”
              </h4>
              <p>CEO</p>
              <a href='https://clutch.co/profile/pletava#highlights'>
                FULL REVIEW ON CLUTCH.CO
              </a>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className='c-item'>
          <div className='c-content'>
            <div className='img-wrapper'>
              <img src='./img/user2.png' alt='Pletava Customer' />
            </div>
            <div>
              <h4>
                “We appreciated Pletava’s commitment to social responsibility
                and their internal culture. In our previous cooperation with
                offshore teams, we had certain miscommunication problems. But,
                with Pletava, we built transparent dialogue and received answers
                to all our questions. Pletava’s HR did a great job and the whole
                recruiting process was really fast and seamless.”
              </h4>
              <p>HEAD OF IT</p>
              <a href='https://clutch.co/profile/pletava#highlights'>
                FULL REVIEW ON CLUTCH.CO
              </a>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className='c-item'>
          <div className='c-content'>
            <div className='img-wrapper'>
              <img src='./img/user3.png' alt='Pletava Customer' />
            </div>
            <div>
              <h4>
                “What we were looking for and found with Pletava was close
                cooperation and proper employees. It’s always hard to start up a
                development team, and it doesn’t matter whether it is in Ireland
                or Ukraine. However, working with Pletava, we can scale the
                benefits of someone taking care of all the administrative work,
                HR functions, or things like renting an office in Kyiv.”
              </h4>
              <p>STEINAR AAS</p>
              <a href='https://clutch.co/profile/pletava#highlights'>
                FULL REVIEW ON CLUTCH.CO
              </a>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className='c-item'>
          <div className='c-content'>
            <div className='img-wrapper'>
              <img src='./img/user4.png' alt='Pletava Customer' />
            </div>
            <div>
              <h4>
                “For us it was important that the company was not just building
                what we asked, but also challenged us in our ask and provide
                consulting along the way. Furthermore, the mission of Pletava
                very much aligned with our own, especially the social impact
                they try to make was a deciding factor for us.”
              </h4>
              <p>NATALIE PERROTT</p>
              <a href='https://clutch.co/profile/pletava#highlights'>
                FULL REVIEW ON CLUTCH.CO
              </a>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className='c-item'>
          <div className='c-content'>
            <div className='img-wrapper'>
              <img src='./img/user5.png' alt='Pletava Customer' />
            </div>
            <div>
              <h4>
                “Anna has been designing UX for us for the past six months, and
                we have been delighted with her work. Her designs have made a
                severe impact on the usability of our product and engagement
                boost which made a positive ROI within the first two weeks of
                rebranding. She has been responsive to our requests and
                responsible for delivering results. We are excited to continue
                growing our business with the help of Anna and Pletava.”
              </h4>
              <p>BRIAN MADSEN</p>
              <a href='https://clutch.co/profile/pletava#highlights'>
                FULL REVIEW ON CLUTCH.CO
              </a>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </ReviewCarouselWrapper>
  )
}

export default ReviewCarousel
