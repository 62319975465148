import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { ContactButton } from './Navbar'
import { NavLink } from 'react-router-dom'

const SolutionsWrapper = styled.div`
  padding: 116px 128px;

  .box-wrapper {
    padding: 32px 80px;
    border: 1px solid #323844;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    img {
      width: auto;
      height: 100%;
    }
  }

  .img-wrapper-sol {
    height: 240px;
    margin-bottom: 24px;
  }

  .best-label {
    color: #747474;
    margin-bottom: 16px;
  }

  .nav-item-margin {
    margin-top: 16px;
  }

  h2,
  h3 {
    color: #252a33;
    font-weight: 600;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 1.5em;
  }

  span {
    display: inline;
  }

  .first-box {
    margin-right: 24px;
  }

  @media (max-width: 768px) {
    padding: 116px 1rem;

    .box-wrapper {
      padding: 16px 32px;
    }
  }

  @media (max-width: 998px) {
    .first-box {
      margin-right: 0;
      margin-bottom: 24px;
    }

    .boxes-row {
      flex-direction: column;
    }
  }
`

const Solutions = () => {
  return (
    <SolutionsWrapper>
      <Container>
        <Row style={{ marginBottom: '48px' }}>
          <Col sm={12} lg={4}>
            <h2>Core services for sustainable software development</h2>
          </Col>
          <Col lg={2}></Col>
          <Col sm={12} lg={6}>
            <p style={{ color: '#747474' }}>
              Whether you need to extend your tech team, save a mission-critical
              software project, or anything in between – we’re here to help.
            </p>
          </Col>
        </Row>
        <Row className='d-flex align-items-stretch justify-content-between boxes-row'>
          <Col className='box-wrapper first-box'>
            <p className='best-label'>
              Best for strategic IT staff augmentation
            </p>
            {/* <div className='img-wrapper-sol'>
                <img src='./img/illustration-your-users.svg' alt='' />
              </div> */}
            <h3>
              <span>Dedicated Development teams</span>
            </h3>
            <p>
              We build sustainable nearshore teams of software engineers and
              other tech experts. You manage your development projects and
              communicate with dedicated developers directly, while Pletava
              takes care of infrastructure and supports you in maintaining a
              tight-knit, cohesive, and effective team in the long run.
            </p>
            <Nav.Link to='/contact-us' as={NavLink} className='nav-item-margin'>
              <ContactButton $bg='#2b93eb' $reverse>
                Contact Us
              </ContactButton>
            </Nav.Link>
          </Col>
          <Col className='box-wrapper'>
            <p className='best-label'>Best for quick project delivery </p>
            {/* <div className='img-wrapper-sol'>
                <img src='./img/illustration-grow-together.svg' alt='' />
              </div> */}

            <h3>
              <span>Software Development Services</span>
            </h3>
            <p>
              In this setup, we provide you with extra capacity to solve a
              specific task or fix an unexpected problem. Leverage our
              cross-domain expertise and fast team sourcing to clear your
              backlog, accelerate time-to-market for your custom software
              solution, or test out the waters before committing to long-term
              team extension.
            </p>
            <Nav.Link to='/contact-us' as={NavLink} className='nav-item-margin'>
              <ContactButton $bg='#2b93eb' $reverse={true}>
                Contact Us
              </ContactButton>
            </Nav.Link>
          </Col>
        </Row>
      </Container>
    </SolutionsWrapper>
  )
}

export default Solutions
