import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

const ServicesWrapper = styled.div`
  padding: 116px 128px;
  padding-top: 32px;

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  h3 {
    color: #252a33;
  }

  .service-container {
    border: 1px solid #e3eaef;
    border-radius: 8px;
    padding: 32px 24px;
    margin: 0 40px 40px 0;
  }

  @media (max-width: 1200px) {
    .service-container {
      margin: 0 24px 24px 0;
    }
  }

  @media (max-width: 768px) {
    padding: 116px 1rem;
    padding-top: 32px;
  }
`

const Services = () => {
  return (
    <ServicesWrapper>
      <Container>
        <Row>
          <Col sm={12} md={12} lg={6} xl={4}>
            <div>
              <h2>Featured Software Engineering &amp; Design Services</h2>
              <p>
                We help you design, develop, and deploy software solutions that
                streamline workflows, boost customer satisfaction, and increase
                revenues.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={4}>
            <div className='service-container'>
              <h3>Mobile Application Development</h3>
              <p>
                As an app developing company, we rely on the latest technologies
                to build UX-driven, high-performance, and flexible native and
                cross-platforms apps.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={4}>
            <div className='service-container'>
              <h3>Custom Web Development</h3>
              <p>
                We build pixel-perfect, responsive, and scalable web apps that
                enable you to provide elegant and functional digital experiences
                to your customers.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={4}>
            <div className='service-container'>
              <h3>UX/UI Design Services</h3>
              <p>
                Our skilled user experience consultants use the latest UI/UX
                architectures and technologies to help you create interfaces and
                UX flows that drive lasting engagement.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6} xl={4}>
            <div className='service-container'>
              <h3>Cloud and DevOps</h3>
              <p>
                Our DevOps services teams aim to make your development lifecycle
                more efficient, making sure that feature delivery, fixes and
                updates deploy in alignment with your timeline.
              </p>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} xl={4}>
            <div className='service-container'>
              <h3>Quality Assurance</h3>
              <p>
                Our experienced QA engineers can help you with Manual testing,
                Automation testing, Functional testing, and Security testing to
                drive reliability and maintainability.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </ServicesWrapper>
  )
}

export default Services
