import React from 'react'

const Card = ({ text }: { text: string }) => {
  return (
    <div>
      <div
        style={{
          padding:
            text === 'AI'
              ? '40px 48px'
              : text === 'MOBILE'
              ? '64px 72px'
              : text === 'WEB'
              ? '48px 56px'
              : text === 'INFRA'
              ? '56px 64px'
              : '40px 48px',
          borderRadius:
            text === 'AI'
              ? '56px'
              : text === 'MOBILE'
              ? '80px'
              : text === 'WEB'
              ? '64px'
              : text === 'INFRA'
              ? '72px'
              : '56px',
        }}
        className={
          text === 'AI'
            ? 'nav-card nav-card-ai'
            : text === 'MOBILE'
            ? 'nav-card nav-card-mobile'
            : text === 'WEB'
            ? 'nav-card nav-card-web'
            : text === 'INFRA'
            ? 'nav-card nav-card-infra'
            : ''
        }
      >
        <h2
          style={{
            fontFamily: 'Nunito',
            fontSize: '64px',
            fontWeight: 700,
            color:
              text === 'AI'
                ? '#e3b1fe'
                : text === 'MOBILE'
                ? '#fcb5c3'
                : text === 'WEB'
                ? '#ffdba7'
                : text === 'INFRA'
                ? '#a4d0fc'
                : '#fff',
          }}
        >
          {text}
        </h2>
      </div>
    </div>
  )
}

export default Card
