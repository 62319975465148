import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const AuthorityWrapper = styled.div`
  /* background-color: #f4fbff; */
  padding: 64px 40px;
  padding-top: 80px;

  h1 {
    color: #252a33;
    margin-bottom: 16px;
  }
  span,
  h3 {
    color: #2b93eb;
  }
  p {
    color: #747474;
  }

  .second-col p {
    color: #252a33;
  }

  .auth-line {
    height: 160px;
    border-right: 1px solid #1d1d1d;
  }

  .auth-vertical {
    transform-origin: 0 100%;
    transform: rotate(-90deg);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    margin: 0;
    margin-left: 24px;
  }

  .top-row {
    min-height: 122px;
  }

  .vert-group {
    display: flex;
    align-items: flex-end;
  }

  @media (max-width: 1024px) {
    .top-row {
      min-height: 80px;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 0;
    .upper-row {
      margin-bottom: 24px;
    }
    .top-row {
      min-height: 80px;
    }
  }
`;

const Authority = () => {
  return (
    <AuthorityWrapper>
      <Container>
        <Row className='justify-content-center'>
          <Col md={12} lg={4} className='upper-row'>
            <h1>
              Your business <span>growth</span> is our priority
            </h1>
            <p>
              Leave behind the stress and risks of plain outsourcing. Pletava
              introduces predictability, broad access to various skill sets, and
              a strategic partnership mindset to your tech development project.
            </p>
          </Col>
          <Col lg={2}></Col>
          <Col md={12} lg={4} className='second-col'>
            <Row>
              <Col xs={4}>
                <div className='top-row'>
                  <h3>4 years</h3>
                  <p>On the IT-battlefield</p>
                </div>
                <div className='vert-group'>
                  <div className='auth-line'></div>
                  <p className='auth-vertical'>RELIABILITY</p>
                </div>
              </Col>
              <Col xs={4}>
                <div className='top-row'>
                  <h3>5 / 5</h3>
                  <p>Rate on Clutch.co</p>
                </div>

                <div className='vert-group'>
                  <div className='auth-line'></div>
                  <p className='auth-vertical'>EXCELLENCE</p>
                </div>
              </Col>
              <Col xs={4}>
                <div className='top-row'>
                  <h3>9.9 / 10</h3>
                  <p>Willing to refer</p>
                </div>

                <div className='vert-group'>
                  <div className='auth-line'></div>
                  <p className='auth-vertical'>LOYALTY</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </AuthorityWrapper>
  );
};

export default Authority;
