import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { ContactButton } from '../components/Navbar'
import ContactContainer from '../components/ContactContainer'
import Footer from '../components/Footer'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { NavLink } from 'react-router-dom'

const CaseStudies = () => {
  return (
    <div style={{ paddingTop: '72px' }}>
      <div className='section-wrapper'>
        <video
          preload='none'
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
          autoPlay={true}
          loop={true}
          controls={false}
          muted
          playsInline
        >
          <source src='./videos/case1.mov' data-wf-ignore='true' />
        </video>
        <div
          style={{
            zIndex: 0,
            backgroundColor: 'rgba(0,0,0,.6)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        ></div>
        <div className='sc-wrap'>
          <div className='section-content'>
            <Container style={{ margin: 0 }}>
              <Row
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Col lg={5}>
                  <div className='sc-heading'>
                    <h1>Festival App</h1>
                    <h4>
                      Large web-based C2C solution for that need to discover
                      music festivals.
                    </h4>
                  </div>

                  <Nav.Link
                    to='/contact-us'
                    as={NavLink}
                    className='nav-item-margin'
                    style={{ display: 'inline-block' }}
                  >
                    <ContactButton
                      $bg='#fff'
                      $reverse={true}
                      $color='#fff'
                      style={{ marginBottom: '40px' }}
                    >
                      Contact Us
                    </ContactButton>
                  </Nav.Link>
                </Col>
                <Col lg={5}>
                  <h4>INSTRUMENTS & TECHNOLOGIES</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      width: '200px',
                      height: '1px',
                      margin: '16px 0',
                    }}
                  />
                  <p>VUE, PS, AWS</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '0',
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{ color: '#fff', fontSize: '40px' }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className='sc-indicators ind1'>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
        </div>
      </div>
      <div className='section-wrapper'>
        <video
          preload='none'
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
          autoPlay={true}
          loop={true}
          controls={false}
          muted
          playsInline
        >
          <source src='./videos/case2.mov' data-wf-ignore='true' />
        </video>
        <div
          style={{
            zIndex: 0,
            backgroundColor: 'rgba(0,0,0,.6)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        ></div>
        <div className='sc-wrap'>
          <div className='section-content'>
            <Container style={{ margin: 0 }}>
              <Row
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Col lg={5}>
                  <div className='sc-heading'>
                    <h1>Social Curator</h1>
                    <h4>
                      Create a social posts easily with our caption templates,
                      stock photos, reel tutorials, story templates, and other
                      resources that save time.
                    </h4>
                  </div>

                  <Nav.Link
                    to='/contact-us'
                    as={NavLink}
                    className='nav-item-margin'
                    style={{ display: 'inline-block' }}
                  >
                    <ContactButton
                      $bg='#fff'
                      $reverse={true}
                      $color='#fff'
                      style={{ marginBottom: '40px' }}
                    >
                      Contact Us
                    </ContactButton>
                  </Nav.Link>
                </Col>
                <Col lg={5}>
                  <h4>INSTRUMENTS & TECHNOLOGIES</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      width: '200px',
                      height: '1px',
                      margin: '16px 0',
                    }}
                  />
                  <p>REACT, NOTION, SLACK, FIREBASE</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '0',
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{ color: '#fff', fontSize: '40px' }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className='sc-indicators ind2'>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
        </div>
      </div>
      <div className='section-wrapper'>
        <video
          preload='none'
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
          autoPlay={true}
          loop={true}
          controls={false}
          muted
          playsInline
        >
          <source src='./videos/case3.mov' data-wf-ignore='true' />
        </video>
        <div
          style={{
            zIndex: 0,
            backgroundColor: 'rgba(0,0,0,.6)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        ></div>
        <div className='sc-wrap'>
          <div className='section-content'>
            <Container style={{ margin: 0 }}>
              <Row
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Col lg={5}>
                  <div className='sc-heading'>
                    <h1>Meta UK Limited</h1>
                    <h4>
                      On-demand feature delivery for high scale system Workspace
                      with 500k+ Daily Active Users.
                    </h4>
                  </div>

                  <Nav.Link
                    to='/contact-us'
                    as={NavLink}
                    className='nav-item-margin'
                    style={{ display: 'inline-block' }}
                  >
                    <ContactButton
                      $bg='#fff'
                      $reverse={true}
                      $color='#fff'
                      style={{ marginBottom: '40px' }}
                    >
                      Contact Us
                    </ContactButton>
                  </Nav.Link>
                </Col>
                <Col lg={5}>
                  <h4>INSTRUMENTS & TECHNOLOGIES</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      width: '200px',
                      height: '1px',
                      margin: '16px 0',
                    }}
                  />
                  <p>REACT, REDUX, TS, FIGMA, TEAMS</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '0',
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{ color: '#fff', fontSize: '40px' }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className='sc-indicators ind3'>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
        </div>
      </div>
      <div className='section-wrapper'>
        <video
          preload='none'
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
          autoPlay={true}
          loop={true}
          controls={false}
          muted
          playsInline
        >
          <source src='./videos/case4.mov' data-wf-ignore='true' />
        </video>
        <div
          style={{
            zIndex: 0,
            backgroundColor: 'rgba(0,0,0,.6)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        ></div>
        <div className='sc-wrap'>
          <div className='section-content'>
            <Container style={{ margin: 0 }}>
              <Row
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Col lg={5}>
                  <div className='sc-heading'>
                    <h1>SAP</h1>
                    <h4>
                      SAP is supported by over 100 years of combined executive
                      and management experience in the manufacturing automation
                      business industry.
                    </h4>
                  </div>

                  <Nav.Link
                    to='/contact-us'
                    as={NavLink}
                    className='nav-item-margin'
                    style={{ display: 'inline-block' }}
                  >
                    <ContactButton
                      $bg='#fff'
                      $reverse={true}
                      $color='#fff'
                      style={{ marginBottom: '40px' }}
                    >
                      Contact Us
                    </ContactButton>
                  </Nav.Link>
                </Col>
                <Col lg={5}>
                  <h4>INSTRUMENTS & TECHNOLOGIES</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      width: '200px',
                      height: '1px',
                      margin: '16px 0',
                    }}
                  />
                  <p>REACT, REDUX, FIGMA, SLACK</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '0',
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{ color: '#fff', fontSize: '40px' }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className='sc-indicators ind4'>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
        </div>
      </div>
      <div className='section-wrapper'>
        <video
          preload='none'
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
          autoPlay={true}
          loop={true}
          controls={false}
          muted
          playsInline
        >
          <source src='./videos/case5.mov' data-wf-ignore='true' />
        </video>
        <div
          style={{
            zIndex: 0,
            backgroundColor: 'rgba(0,0,0,.6)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        ></div>
        <div className='sc-wrap'>
          <div className='section-content'>
            <Container style={{ margin: 0 }}>
              <Row
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Col lg={5}>
                  <div className='sc-heading'>
                    <h1>FinTech Banking App</h1>
                    <h4>
                      Large web-based solution for enterprise companies that
                      need to perform performance assessments of their
                      employees.
                    </h4>
                  </div>
                  <Nav.Link
                    to='/contact-us'
                    as={NavLink}
                    className='nav-item-margin'
                    style={{ display: 'inline-block' }}
                  >
                    <ContactButton
                      $bg='#fff'
                      $reverse={true}
                      $color='#fff'
                      style={{ marginBottom: '40px' }}
                    >
                      Contact Us
                    </ContactButton>
                  </Nav.Link>
                </Col>
                <Col lg={5}>
                  <h4>INSTRUMENTS & TECHNOLOGIES</h4>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      width: '200px',
                      height: '1px',
                      margin: '16px 0',
                    }}
                  />
                  <p>HTML, CSS, TS, REACT</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '0',
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      sx={{ color: '#fff', fontSize: '40px' }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className='sc-indicators ind5'>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
          <div className='indicator-item'></div>
        </div>
      </div>
      <ContactContainer extraMargin />
      <Footer />
    </div>
  )
}

export default CaseStudies
