import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const MagazinesWrapper = styled.div`
  /* background-color: #f4fbff; */
  padding: 96px 0 84px;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .lastMag {
    display: flex;
    justify-content: center;
  }
`;

const Magazines = () => {
  return (
    <MagazinesWrapper>
      <Container>
        <Row className='justify-content-center'>
          <Col xs={6} lg={2}>
            <img src='./img/forbes.svg' alt='' />
          </Col>
          <Col
            xs={6}
            lg={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src='./img/dou.png' alt='' style={{ width: '50%' }} />
          </Col>

          <Col xs={6} lg={2}>
            <img src='./img/techeu.svg' alt='' />
          </Col>
          <Col xs={6} lg={2}>
            <img src='./img/therecursive.svg' alt='' />
          </Col>

          <Col xs={12} lg={2} className='lastMag'>
            <img src='./img/svt.svg' alt='' />
          </Col>
        </Row>
      </Container>
    </MagazinesWrapper>
  );
};

export default Magazines;
