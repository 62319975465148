import React from 'react'
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

const tooltip = (
  <Tooltip id='tooltip'>
    FAANG (Facebook, Apple, Amazon, Netflix, Google) - acronym that stands for
    top Big Tech companies. Pletava's CEO Mike Doroshenko is an ex-Facebook
    engineer
  </Tooltip>
)

const WhyPletavaWrapper = styled.div`
  padding: 116px 128px;
  padding-top: 32px;

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  .why-container {
    border: 1px solid #e3eaef;
    border-radius: 8px;
    padding: 48px 64px;
    background: linear-gradient(
      to right,
      rgba(197, 231, 250, 0.4),
      rgba(244, 251, 255, 0.5)
    );
  }

  li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 16px;
  }

  li::before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
    width: 10px;
    min-width: 10px;
    min-height: 10px;
    height: 10px;
    background-color: #2b93eb;
    border-radius: 50%;
  }

  .why-content {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1200px) {
    .why-content {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 768px) {
    padding: 116px 1rem;
    padding-top: 32px;
    .why-container {
      padding: 24px;
    }

    ul {
      padding-left: 0;
    }
  }
`

const WhyPletava = () => {
  return (
    <WhyPletavaWrapper>
      <Container>
        <Row>
          <Col lg={12} style={{ paddingLeft: 0, marginBottom: '16px' }}>
            <h2>Why many companies choose Pletava?</h2>
          </Col>
          <Col lg={12} className='why-container'>
            <Row className='why-content'>
              <Col lg={12} xl={6}>
                <ul>
                  <li>
                    <p>
                      The unique synergy of our Ukranian soul and European
                      business values results in Pletava’s lean organizational
                      culture that cuts down unnecessary costs and drives
                      transparency, forthright dialogue, and human connection in
                      every project we commit to.
                    </p>
                  </li>
                  <li>
                    <p>
                      We have{' '}
                      <OverlayTrigger placement='top' overlay={tooltip}>
                        <span style={{ color: '#2b93eb' }}>FAANG</span>
                      </OverlayTrigger>{' '}
                      DNA, which helps grasp complex things on the fly and
                      understand business needs much deeper.
                    </p>
                  </li>
                  <li>
                    <p>
                      Own unique automated employee selection system, which
                      allows us to process about 1000 candidates every month and
                      select abnormally talented people for the team.
                    </p>
                  </li>
                  <li>
                    <p>
                      You pay only for hours worked, not for the developer's
                      presence in the office. We cover extra expenses, such as
                      sick leave, vacation, and workspace. No need to worry
                      about motivating your dev team.
                    </p>
                  </li>
                  <li>
                    <p>
                      We provide detailed weekly and monthly reports, allowing
                      you to easily track progress.
                    </p>
                  </li>
                </ul>
              </Col>
              <Col lg={0} xl={1}></Col>
              <Col lg={12} xl={5}>
                <img
                  src='./img/illustration-your-users.svg'
                  alt='Pletava'
                  style={{ width: '100%', marginBottom: '24px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </WhyPletavaWrapper>
  )
}

export default WhyPletava
