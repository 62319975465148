import React from 'react';
import styled from 'styled-components';
import ReviewCarousel from './ReviewCarousel';

const ReviewsSliderContainerWrapper = styled.div`
  padding: 116px 128px;
  padding-top: 32px;
  /* background-color: #f4fbff; */

  @media (max-width: 768px) {
    padding: 116px 1rem;
    padding-top: 32px;
  }
`;

const ReviewsSliderContainer = () => {
  return (
    <ReviewsSliderContainerWrapper>
      <h3>Companies like yours trust us</h3>
      <p style={{ maxWidth: '640px', marginBottom: '40px' }}>
        Startups, tech product agencies, and enterprises around the world choose
        to work with Pletava to bring both strong technical expertise and a
        collaborative, engaged attitude to reach their growth targets more
        efficiently
      </p>
      <ReviewCarousel />
    </ReviewsSliderContainerWrapper>
  );
};

export default ReviewsSliderContainer;
