import React from 'react'
import {
  Button,
  Container,
  Flex,
  StyledHeader,
  Image,
} from '../components/HomeHeader'
import Footer from '../components/Footer'
import { Col, Row, Container as BsContainer, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import { NavLink } from 'react-router-dom'

const StyledInfo = styled.div`
  padding: 0 0 84px;
  padding-top: 104px;
  padding-bottom: 0;

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  p {
    font-size: 16px;
    color: #323844;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`

const StyledVaccancies = styled.div`
  padding: 116px 128px;
  padding-top: 96px;

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  h3 {
    color: #252a33;
  }

  h4 {
    font-weight: 700;
  }

  p {
    font-size: 16px;
    color: #323844;
    margin-bottom: 0;
  }

  span {
    font-weight: 500;
  }

  .vac-container {
    border: 1px solid #e3eaef;
    border-radius: 8px;
    padding: 32px 24px;
    margin-bottom: 40px;
  }

  .vac-header {
    display: flex;
  }

  .vac-apply {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1200px) {
    .vac-container {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 768px) {
    padding: 116px 1rem;

    h2 {
      font-size: 1em;
      width: 65%;
    }

    span {
      font-size: 14px;
    }

    .vac-header {
      flex-direction: column;
    }

    .vac-item:nth-child(1) {
      margin-bottom: 8px;
    }

    .vac-item:nth-child(2) {
      margin-bottom: 16px;
    }

    .vac-apply {
      justify-content: flex-start;
    }
  }
`

const Career = () => {
  return (
    <div style={{ paddingTop: '72px' }}>
      <StyledHeader style={{ paddingBottom: '152px' }}>
        <Container>
          <Flex>
            <div style={{ marginRight: '24px' }}>
              <h2 style={{ marginBottom: '16px' }}>
                Your next tech job starts here
              </h2>

              <p style={{ marginBottom: '32px' }}>
                Join Pletava and work with clients that share our ambition to
                create a positive impact and a better tomorrow.
              </p>

              <a href='#vac-start'>
                <Button $bg='#2b93eb' $color='#fff'>
                  Apply
                </Button>
              </a>
            </div>

            <Image
              src='./img/illustration-flowing-conversation.svg'
              alt='Careers'
            />
          </Flex>
        </Container>
      </StyledHeader>

      <StyledInfo>
        <BsContainer
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '64px 20px',
            width: '1000px',
            maxWidth: '100%',
          }}
        >
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Col lg={5}>
              <h2>Work on projects that help make the world better.</h2>
            </Col>
            <Col lg={6}>
              <p>
                Pletava has over 30 experts in Ukraine and Ireland united by a
                common goal to create a positive impact.
              </p>
              <p>
                We create teams of engineers and designers for projects
                worldwide. Our key expertise lies in HealthTech, EdTech, and
                GreenTech – domains that push humanity forward.
              </p>
            </Col>
          </Row>
        </BsContainer>
      </StyledInfo>

      <StyledVaccancies id='vac-start'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <h2
            style={{
              marginRight: '16px',
              marginBottom: 0,
              lineHeight: 1,
            }}
          >
            Explore our career opportunities
          </h2>
          <p style={{ color: '#747474' }}>( 3 of 3 )</p>
        </div>

        <BsContainer>
          <Row>
            <Col lg={12} className='vac-container'>
              <BsContainer>
                <Row
                  style={{
                    marginBottom: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Col lg={11}>
                    <h4>Frontend Software Engineer (React Native)</h4>
                    <div className='vac-header'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className='vac-item'
                      >
                        <LocationOnIcon
                          sx={{
                            fontSize: 32,
                            marginRight: '8px',
                            color: '#2b93eb',
                          }}
                        />
                        <span style={{ marginRight: '40px' }}>
                          Remote, Any Country
                        </span>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className='vac-item'
                      >
                        <ConnectWithoutContactIcon
                          sx={{
                            fontSize: 32,
                            marginRight: '8px',
                            color: '#2b93eb',
                          }}
                        />
                        <span style={{ fontWeight: 700, marginRight: '4px' }}>
                          $ 200
                        </span>
                        <span> Refferal Bonus</span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={1} className='vac-apply'>
                    <Nav.Link
                      to='/career/frontend-software-engineer'
                      as={NavLink}
                      style={{ display: 'inline-block' }}
                    >
                      <Button $bg='#2b93eb' $color='#fff'>
                        Apply
                      </Button>
                    </Nav.Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      We're seeking a passionate and skilled individual to
                      contribute to the development of cutting-edge mobile
                      applications. If you have a strong background in React
                      Native and a keen eye for user-centric design, apply now
                      to be part of an innovative tech environment where your
                      expertise will shape the future of mobile experiences.
                    </p>
                  </Col>
                </Row>
              </BsContainer>
            </Col>
            <Col lg={12} className='vac-container'>
              <BsContainer>
                <Row
                  style={{
                    marginBottom: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Col lg={11}>
                    <h4>Senior UI/UX designer</h4>
                    <div className='vac-header'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className='vac-item'
                      >
                        <LocationOnIcon
                          sx={{
                            fontSize: 32,
                            marginRight: '8px',
                            color: '#2b93eb',
                          }}
                        />
                        <span style={{ marginRight: '40px' }}>
                          Remote, Any Country
                        </span>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className='vac-item'
                      >
                        <ConnectWithoutContactIcon
                          sx={{
                            fontSize: 32,
                            marginRight: '8px',
                            color: '#2b93eb',
                          }}
                        />
                        <span style={{ fontWeight: 700, marginRight: '4px' }}>
                          $ 200
                        </span>
                        <span> Refferal Bonus</span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={1} className='vac-apply'>
                    <Nav.Link
                      to='/career/ui-designer'
                      as={NavLink}
                      style={{ display: 'inline-block' }}
                    >
                      <Button $bg='#2b93eb' $color='#fff'>
                        Apply
                      </Button>
                    </Nav.Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      We're on the lookout for a UI/UX dev who can blend
                      artistic vision with user-centric design principles. As a
                      key contributor, you'll play a pivotal role in shaping the
                      aesthetic and functional aspects of our digital products.
                      If you have a proven track record in UI/UX design, a
                      passion for innovation, and the ability to mentor junior
                      designers, apply now to lead the charge in crafting
                      exceptional user experiences.
                    </p>
                  </Col>
                </Row>
              </BsContainer>
            </Col>
            <Col lg={12} className='vac-container'>
              <BsContainer>
                <Row
                  style={{
                    marginBottom: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Col lg={11}>
                    <h4>Sales Manager</h4>
                    <div className='vac-header'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className='vac-item'
                      >
                        <LocationOnIcon
                          sx={{
                            fontSize: 32,
                            marginRight: '8px',
                            color: '#2b93eb',
                          }}
                        />
                        <span style={{ marginRight: '40px' }}>
                          Galway, Ireland
                        </span>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        className='vac-item'
                      >
                        <ConnectWithoutContactIcon
                          sx={{
                            fontSize: 32,
                            marginRight: '8px',
                            color: '#2b93eb',
                          }}
                        />
                        <span style={{ fontWeight: 700, marginRight: '4px' }}>
                          $ 200
                        </span>
                        <span> Refferal Bonus</span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={1} className='vac-apply'>
                    <Nav.Link
                      to='/career/sales-manager'
                      as={NavLink}
                      style={{ display: 'inline-block' }}
                    >
                      <Button $bg='#2b93eb' $color='#fff'>
                        Apply
                      </Button>
                    </Nav.Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      Join us as a Senior Sales Manager! We're looking for an
                      experienced professional to drive sales success, foster
                      client relationships, and contribute to our dynamic team.
                      If you have a proven track record in sales management,
                      apply now to play a key role in our growth.
                    </p>
                  </Col>
                </Row>
              </BsContainer>
            </Col>
          </Row>
        </BsContainer>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ color: '#747474' }}>1 of 1</p>
        </div>
      </StyledVaccancies>

      <Footer />
    </div>
  )
}

export default Career
