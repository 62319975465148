import React, { useEffect, useRef, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Card from './HeaderComponents/Card';

export const StyledHeader = styled.header`
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 144px 0 280px;
  z-index: 5;

  h1 {
    font-size: 80px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 0 144px;
    h1 {
      font-size: 32px;
    }
  }
`;

export const Image = styled.img`
  width: 400px;
  margin-left: 40px;

  @media (max-width: 768px) {
    margin: 40px 0 30px;
  }
`;

const AiCard = styled.div`
  left: 100px;
  top: 140px;

  @media (max-width: 768px) {
    left: 8px;
    top: 160px;
  }
`;

const WebCard = styled.div`
  right: 60px;
  top: 140px;

  @media (max-width: 768px) {
    right: -56px;
    top: 120px;
  }
`;

const MobileCard = styled.div`
  left: 120px;
  top: 450px;

  @media (max-width: 768px) {
    left: -100px;
    top: 520px;
  }
`;

const InfraCard = styled.div`
  right: 120px;
  top: 450px;

  @media (max-width: 768px) {
    right: -56px;
    top: 560px;
  }
`;

const SubheaderP = styled.p`
  margin-bottom: 1.5rem;
  text-align: center;
  padding: 0 160px;
  font-size: 21px;
  font-weight: 600;

  @media (max-width: 768px) {
    padding: 0 24px;
    font-size: 14px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  & > div,
  & > ul {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

export const Container = styled.div`
  width: 1000px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const Button = styled.button<{
  $bg?: string;
  $color?: string;
}>`
  border-radius: 32px;
  border: 1px solid ${(props) => props.$bg || '#fff'};
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 24px;
  background-color: ${(props) => props.$bg || '#fff'};
  color: ${(props) => props.$color || '#1d1d1f'};
  transition: all 0.2s ease;

  &:hover {
    background-color: #3d3d3d;
  }

  @media (max-width: 786px) {
    font-size: 12px;
  }
`;

export const SecondaryButton = styled.button`
  border-radius: 32px;
  font-weight: 500;
  border: 2px solid #cad0d9;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 24px;
  background-color: #fff;
  color: #1d1d1f;
  transition: all 0.2s ease;

  &:hover {
    border: 2px solid #a3a3a3;
  }

  @media (max-width: 786px) {
    font-size: 12px;
  }
`;

const HomeHeader = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const speed = 0.1;
  const speedSlow = 0.00012;
  const speedOpacity = 0.0015;
  const speedFast = 0.18;

  useEffect(() => {
    const newIsMobile = windowWidth < 768;
    if (newIsMobile !== isMobile) {
      setIsMobile(newIsMobile);
    }
  }, [isMobile, windowWidth]);

  // Cards resize width
  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        setWindowWidth(entry.contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={elementRef}
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StyledHeader>
        <h1
          style={{
            marginBottom: '1.5rem',
            textAlign: 'center',
          }}
        >
          One partner for all your business software.
        </h1>

        <SubheaderP>
          Scale and save with dedicated teams of experts, custom software
          development, and product discovery.
        </SubheaderP>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '24px' }}>
            <Nav.Link
              to='/contact-us'
              as={NavLink}
              style={{ display: 'inline-flex' }}
            >
              <Button $bg='#1d1d1f' $color='#ffffff'>
                Let's build
              </Button>
            </Nav.Link>
          </div>

          <a href='#problems'>
            <SecondaryButton>Learn more</SecondaryButton>
          </a>
        </div>
      </StyledHeader>
      {scrollPosition < 750 && (
        <>
          <AiCard
            style={{
              transform: isMobile
                ? 'none'
                : `translate(-${scrollPosition * speed}px, -${
                    scrollPosition * speed * 0.8
                  }px)`,
              position: 'absolute',
              scale: `${(isMobile ? 0.5 : 0.8) + scrollPosition * speedSlow}`,
              opacity: `${1 - scrollPosition * speedOpacity}`,
            }}
          >
            <Card text='AI' />
          </AiCard>
          <MobileCard
            style={{
              transform: isMobile
                ? 'none'
                : `translate(-${scrollPosition * speedFast}px, ${
                    scrollPosition * speed * 0.5
                  }px)`,
              position: 'absolute',
              scale: `${(isMobile ? 0.3 : 0.58) + scrollPosition * speedSlow}`,
              opacity: `${1 - scrollPosition * speedOpacity}`,
            }}
          >
            <Card text='MOBILE' />
          </MobileCard>
          <WebCard
            style={{
              transform: isMobile
                ? 'none'
                : `translate(${scrollPosition * speed}px, -${
                    scrollPosition * speed * 0.8
                  }px)`,
              position: 'absolute',
              scale: `${(isMobile ? 0.4 : 0.7) + scrollPosition * speedSlow}`,
              opacity: `${1 - scrollPosition * speedOpacity}`,
            }}
          >
            <Card text='WEB' />
          </WebCard>
          <InfraCard
            style={{
              transform: isMobile
                ? 'none'
                : `translate(${scrollPosition * speed}px, ${
                    scrollPosition * speed * 0.5
                  }px)`,
              position: 'absolute',
              scale: `${(isMobile ? 0.3 : 0.6) + scrollPosition * speedSlow}`,
              opacity: `${1 - scrollPosition * speedOpacity}`,
            }}
          >
            <Card text='INFRA' />
          </InfraCard>
        </>
      )}
    </div>
  );
};

export default HomeHeader;
