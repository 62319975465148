import React from 'react'
import ContactContainer from '../components/ContactContainer'
import Footer from '../components/Footer'

const ContactUs = () => {
  return (
    <div style={{ paddingTop: '72px' }}>
      <ContactContainer extraMargin />
      <Footer />
    </div>
  )
}

export default ContactUs
