import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import ContactUs from './pages/ContactUs'
import Testimonials from './pages/Testimonials'
import CaseStudies from './pages/CaseStudies'
import Career from './pages/Career'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Error from './pages/Error'
import ScrollToTop from './hooks/ScrollToTop'
import Terms from './pages/Terms'
import CareerFrontend from './components/CareerFrontend'
import UiDesigner from './components/UiDesigner'
import SalesManager from './components/SalesManager'

const App = () => {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/testimonials' element={<Testimonials />} />
        <Route path='/case-studies' element={<CaseStudies />} />
        <Route path='/career' element={<Career />} />
        <Route
          path='/career/frontend-software-engineer'
          element={<CareerFrontend />}
        />
        <Route path='/career/ui-designer' element={<UiDesigner />} />
        <Route path='/career/sales-manager' element={<SalesManager />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-use' element={<Terms />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </>
  )
}

export default App
