import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ContactButton } from './Navbar';
import styled from 'styled-components';

const ApplyContainer = styled.div`
  margin: 32px 192px;
  margin-top: 72px;
  /* background-color: #f4fbff; */

  .apply-form {
    padding: 96px 80px;
    background-color: #fbfdff;
    border: 1px solid #e3eaef;
    border-radius: 8px;
  }

  h2 {
    color: #252a33;
    font-weight: 600;
    font-size: 1.6em;
  }

  p {
    font-size: 16px;
    color: #323844;
  }

  @media (max-width: 768px) {
    margin: 24px 1rem;

    .apply-form {
      padding: 32px 24px;
    }
  }
`;

const ApplyForm = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [inpKey, setInpKey] = useState<string>('clean');

  const handleFileChange = (event: React.FormEvent) => {
    const files = (event.target as HTMLInputElement).files;

    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length < 40) {
      setName(newValue);
    }
  }, []);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length < 40) {
      setEmail(newValue);
    }
  }, []);

  const handleSubmit = async ({
    name,
    email,
    file,
  }: {
    name: string;
    email: string;
    file: File | undefined;
  }) => {
    if (name !== '' && email !== '' && file !== undefined) {
      //   await addDoc(contactsCollectionRef, {
      //     Name: name,
      //     Email: email,
      //     Company: company,
      //     CreatedAt: serverTimestamp(),
      //   })
      setName('');
      setEmail('');
      setFile(undefined);
      setInpKey(inpKey === 'clean' ? 'full' : 'clean');
      alert(
        'Thank you for taking the first step for our partnership! We will review your CV and let you know if you are a strong match with this role.'
      );
    } else {
      alert(
        'Some of the inputs are empty or invalid. Please check your answers and submit again.'
      );
    }
  };

  return (
    <ApplyContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <h2>Apply:</h2>
      </div>

      <div className='apply-form'>
        <TextField
          id='nameId'
          value={name}
          label='Name'
          variant='outlined'
          required
          style={{ width: '100%', marginBottom: '16px' }}
          onChange={handleNameChange}
        />
        <TextField
          id='emailId'
          value={email}
          label='Email'
          variant='outlined'
          required
          style={{ width: '100%', marginBottom: '16px' }}
          onChange={handleEmailChange}
        />
        <label htmlFor='file-upload' style={{ marginRight: '16px' }}>
          CV:
        </label>
        <input
          id={'file-upload'}
          type='file'
          style={{ fontSize: '16px' }}
          onChange={handleFileChange}
          key={inpKey}
        />
        <p style={{ margin: '24px 0', fontWeight: 300 }}>
          By submitting this form you are agreeing with our{' '}
          <span style={{ color: '#747474', textDecoration: 'underline' }}>
            <Nav.Link
              to='/privacy-policy'
              as={NavLink}
              style={{ display: 'inline' }}
            >
              privacy policy
            </Nav.Link>
          </span>
          .
        </p>
        <ContactButton
          className='contact-submit'
          $bg='#2b93eb'
          $color='#252a33'
          onClick={() => handleSubmit({ name, email, file })}
          $disabled={name === '' || email === '' || file === undefined}
          disabled={name === '' || email === '' || file === undefined}
        >
          Contact Us
        </ContactButton>
      </div>
    </ApplyContainer>
  );
};

export default ApplyForm;
