import React from 'react'
import Magazines from '../components/Magazines'
import ReviewsSliderContainer from '../components/ReviewsSliderContainer'
import ContactContainer from '../components/ContactContainer'
import Footer from '../components/Footer'

const Testimonials = () => {
  return (
    <div style={{ paddingTop: '72px' }}>
      <Magazines />
      <ReviewsSliderContainer />
      <ContactContainer />
      <Footer />
    </div>
  )
}

export default Testimonials
